import request from '@/utils/request'

export function generateOtp(data) {
    return request({
        url: '/api/sms/generate',
        method: 'post',
        params: data
    })
}

export function verifyOtp(data) {
    return request({
        url: '/api/sms/vertify',
        method: 'post',
        params: data
    })
}

export function searchCustomer(data){
    return request({
        url: '/api/user/searchCustomer',
        method: 'post',
        params: data
    })
}

export function odsAddOrUpdate(data){
    return request({
        url: '/api/user/addOrUpdate',
        method: 'post',
        data
    })
}

export function searchStoreName(data){
    return request({
        url: '/api/user/searchStore',
        method: 'post',
        params: data
    })
}

export function getAddress(data){
    return request({
        url: '/api/user/getAddress',
        method: 'post',
        params: data
    })
}

export function getAddressByZipCode(data){
    return request({
        url: '/api/user/getAddressByZipCode',
        method: 'post',
        params: data
    })
}

export function checkAndGetBearerToken(data){
    return request({
        url: '/api/auth/checkAndGetBearerToken',
        method: 'post',
        params: data
    })
}

export function getSession(data) {
    return request({
        url: '/api/auth/getSession',
        method: 'post',
        params: data
    })
}

export function getAuthorizeUrl(data) {
    return request({
        url: '/api/tplogin/getAuthorizeUrl',
        method: 'post',
        params: data
    })
}

export function getThirdPartUserInfo(data) {
    return request({
        url: '/api/tplogin/getUserInfo',
        method: 'post',
        params: data
    })
}

export function sendLineInfo(data) {
    return request({
        url: '/api/eform/sendLineInfo',
        method: 'post',
        params: data
    })
}