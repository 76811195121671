import axios from 'axios'

// create an axios instance
const service = axios.create({
  // 线下
  // baseURL: 'http://127.0.0.1:3000/',
  // 测试
  baseURL: 'https://www.dev-eform.ap.elcompanies.com/',
  // 测试ado
  // baseURL: 'https://app-ap-southeastasia-nonprod-lpd-digital-ado.ase-ap-southeastasia-nonprod-shared.ap.elcompanies.net/',
  // 测试V3
  // baseURL: 'https://app-ap-southeastasia-nonprod-lpd-digitalformrl-kr-v3.ase-ap-southeastasia-np-shared-v3.appserviceenvironment.net/',
  // 生产
  // baseURL: 'https://www.eform.ap.elcompanies.com/',
  timeout: 300000 // request timeout
})
 
// request interceptor
service.interceptors.request.use(
  config => {
    if (config.method === 'get') {
      config.params = { ...config.params, timestamp: Date.now() };
  }
    return config
  },
  error => {
    // do something with request errorx
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const status = response.status
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (status !== 200) {
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  async error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
